/*fonts*/
@font-face {
  font-family: 'UCU Charles Script';
  src: url('./fonts/UCU charles script.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html, body, #app, #app>div {
  height: 100%;
  background: #282828;
}

.header{
  padding-top: 20%;
}

.title {
  text-align: center;
  color: #FFF;
  font-family: "UCU Charles Script", cursive;
  font-size: xxx-large;

}

.description {
  text-align: center;
  color: #FFF;
  font-family: "UCU Charles Script", cursive;
  font-size: x-large;
  margin-top: 5px;
}